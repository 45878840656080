import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const GuidePage = () => (
  <Layout>
    <SEO title="Guide" />
    <div className="edge trim align-baseline site-padding-vertical">
        <h1>Guide</h1>
        <p className="text-preset-large-intro">Page coming soon</p>
    </div>
  </Layout>
)

export default GuidePage
